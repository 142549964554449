// Import the edliy_utils
import {
    makeResponsive,
    placeTitle,
    placeImage,
    placeInput,
    placeSlider,
    hoverMe,
    placeRec,
    hiddenPt,
    fixedPt,
    clearInputFields,
    dragMe,
    placeArrow,
    placeGravity,
    placeMiddleText,
    placeLine,
    placePoint,
    placeGlider,
    placeRuler,
    placeLeftText,
    placeRightText,
    placeSliderSwitch,
    placeCircle,
    placeLogo,
    placeShuffle,
    placeTest,
    placeErase,
    placeBoldText,
    placeBoldLeftText,
    placeText
} from '../Utils';
const Boxes = {
  box1: function () {
	JXG.Options.board.minimizeReflow = 'none';
  JXG.Options.point.showInfoBox=false;
  JXG.Options.point.highlight=false;
  JXG.Options.image.highlight=false;
  JXG.Options.text.highlight=false;
  JXG.Options.text.fixed=true;
  JXG.Options.curve.highlight=false;
  JXG.Options.text.cssDefaultStyle='fontFamily:Oswald;'
  var brd1 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-8, 10, 12, -10],keepaspectratio: true, axis:false, ticks:true, grid:true, showCopyright:false, showNavigation:false, pan:{enabled:false}, zoom:{enabled:false}});
  //brd1.suspendUpdate();
  makeResponsive(brd1);
  brd1.options.layer['image'] =12;
  brd1.options.layer['circle'] =13;
  brd1.options.layer['chart'] =14;
  brd1.options.layer['sector'] =15;
  brd1.options.layer['line'] =15;
//	brd1.create('text', [6, 11.5, 'Fraction'],{highlight:false, display:'internal', anchorX:'middle', anchorY:'middle', CssStyle:'fontFamily:Oswald;fontWeight:bold',fontSize:function(){return Math.round(32*brd1.canvasWidth/800.)}, fixed:true});
  placeTitle(brd1, 'Exponents', '(Evaluating an exponent)');
  placeLogo(brd1);
  var reload = placeShuffle(brd1, 'left');
  var test = placeErase(brd1, 'right');
  var k =0;
  placeMiddleText(brd1, 2, 6.5, 'Enter the base and exponent values in the provided fields.')
  //var reload =brd1.create('image', ['/assets/shuffle.svg', [-2.25,3.4],[1.2, 1.2]], {opacity:1, fixed:true});
  placeImage(brd1, '/assets/curved-arrow.svg', 0.5, -1.5, 1, 0);
  placeImage(brd1, '/assets/curved-arrow.svg', 3.9, 1.25, 1, 90);
  var x =placeBoldText(brd1, -2, 0, 'X');
  x.setAttribute({color:'green'});
  var n = placeBoldText(brd1, -1.7, 0.5, 'n');
  n.setAttribute({color:'red'});
  placeBoldText(brd1, -1.0, 0., '=');
  var base =placeBoldText(brd1, -0.25, -1.25, 'Base');
  base.setAttribute({color:'green'});
  var power =placeBoldText(brd1, 4.75, 2.65, 'Exponent');
  power.setAttribute({color:'red'});
  ///////////////////////////////////////////////
  test.setLabel('Tap to hide results')
  test.label.setAttribute({visible:false, alignX:'middle', offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
  test.on('over', function () {this.label.setAttribute({visible:true});});
  test.on('out', function () {this.label.setAttribute({visible:false});});
  test.on('down', function(){ans.setAttribute({visible:false});eq.setAttribute({visible:false});});
  /////////////////////////////////////////////////////////////////////////////////////////////
  reload.setLabel('Tap to display results')
  reload.label.setAttribute({visible:false, alignX:'middle', offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
  reload.on('over', function () {this.label.setAttribute({visible:true});});
  reload.on('out', function () {this.label.setAttribute({visible:false});});
  reload.on('down', function(){eq.setAttribute({visible:true}); ans.setAttribute({visible:true})});
  //brd1.create('text', [4, 6.5, 'Select slices that make'],{display:'internal', alignX:'right'});
  //placeMiddleText(brd1, 3.0, 7.5, ()=>fracArr[k]+'/'+lenArr[k]);
  var eq = placeText(brd1, 5., 0, 32, '=');
  var Inp =placeInput(brd1, 0, 0, "2");
  var power = placeInput(brd1, 2.1, 1.1, "3");
  var gun = function(){
    let a =[];
    for(let x=0; x<power.Value(); x++)
    {
      a[x] =Inp.Value();
    }
    return a;
  }
  var ans =placeBoldLeftText(brd1, 5.5, 0, function(){return gun().join(" x ") + ' = ' + Math.pow(Inp.Value(), power.Value());});
  //ques.setText();
  //placeMiddleText(brd1, 3.0, 0.5, 'Click on a Slice to Select/Deselect it');
  /////////////////////////////////////////////////////////////////////////////////////////////
  //brd1.create('text', [()=>6+2.75*Math.cos(0.5*type1*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())), ()=>3+2.75*Math.sin(0.5*type1*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())), ()=>'Bulldog ('+type1.valueOf()+'/'+(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())+')'],{display:'internal',anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(16*brd1.canvasWidth/800.)}});
  //brd1.create('text', [()=>6+2.75*Math.cos((type1+0.5*type2)*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())), ()=>3+2.75*Math.sin((type1+0.5*type2)*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())), ()=>'Doberman ('+type2.valueOf()+'/'+(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf()) +')' ],{display:'internal',anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(16*brd1.canvasWidth/800.)}});
  //brd1.create('text', [()=>6+2.75*Math.cos((type1+type2+0.5*type3)*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())), ()=>3+2.75*Math.sin((type1+type2+0.5*type3)*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())), ()=>'Grehound ('+type3.valueOf()+'/'+(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())+')'],{display:'internal',anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(16*brd1.canvasWidth/800.)}});
  //brd1.create('text', [()=>6+2.75*Math.cos((type1+type2+type3+0.5*type4)*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())), ()=>3+2.75*Math.sin((type1+type2+type3+0.5*type4)*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())), ()=>'Dalmatian ('+type4.valueOf()+'/'+(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())+')'],{display:'internal',anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(16*brd1.canvasWidth/800.)}});
  //brd1.create('text', [()=>6+2.75*Math.cos((type1+type2+type3+type4+0.5*type5)*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())), ()=>3+2.75*Math.sin((type1+type2+type3+type4+0.5*type5)*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())), ()=>'Shar-Pie ('+type5.valueOf()+'/'+(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf()) +')'],{display:'internal',anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(16*brd1.canvasWidth/800.)}});
//
 var test = function()
 { let total=0;
   for(let i=0; i<lenArr[k]; i++)
   {
     total = total + Math.abs(Math.sin(status[i]*Math.PI/2));
   }
   return total;
 }
},
}
export default Boxes;
